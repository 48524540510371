/* istanbul ignore file */
'use client'

import { ErrorComponent } from 'next/dist/client/components/error-boundary'
import { ComponentProps, FC, useEffect, useRef } from 'react'
import { ErrorPage } from '../../components/client'
import { useTranslation } from '../../lib/hooks'

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/new-relic-browser/index.d.ts
declare global {
    export interface Window {
        newrelic?: {
            noticeError: (
                error: Error | string,
                customAttributes?: Record<string, string | number>
            ) => void
        }
    }
}

type Props = ComponentProps<ErrorComponent>

const InternalErrorPage: FC<Props> = props => {
    const { t } = useTranslation()
    const errorLogged = useRef(false)

    useEffect(() => {
        if (errorLogged.current) {
            return
        }

        if (window.newrelic?.noticeError) {
            window.newrelic.noticeError(props.error)
        } else {
            console.error(props.error)
        }

        errorLogged.current = true
    }, [props.error])

    return (
        <ErrorPage
            title={t('common:errorPage.500')}
            homepageButton
            retryButton
        />
    )
}

export default InternalErrorPage
